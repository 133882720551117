<template>
  <CRow>
    <CCol>
      <label>{{ label }}:</label>
    </CCol>
    <CCol>
      <input
        :ref="inputName"
        class="mr-3"
        type="file"
        horizontal
        @change="changeFile"
      />
    </CCol>
    <CCol>
      <CButton color="primary" :disabled="!canUpload" @click="uploadFile"
        >Upload</CButton
      >
    </CCol>
  </CRow>
</template>
<script>
export default {
  name: 'KBFileUpload',
  props: {
    inputName: {
      type: String,
      required: false,
      default: 'inputField',
    },
    qualifier: {
      type: String,
      required: false,
    },
    file: {
      type: Object,
      required: false,
    },
    label: {
      type: String,
      required: false,
      default: 'KB Upload',
    },
  },
  data() {
    return {
      currentFile: this.file,
    };
  },
  computed: {
    canUpload() {
      if (!this.currentFile) return false;
      return true;
    },
  },
  methods: {
    changeFile() {
      let f = this.$refs[this.inputName].files[0];
      if (f.name.indexOf(this.qualifier) < 0) {
        this.$swal
          .fire({
            icon: 'error',
            title: 'Invalid File',
            text: 'This file does not appear to be the correct file.  Please try again.',
          })
          .then(() => {
            this.$refs[this.inputName].value = '';
            this.currentFile = null;
          });
      } else {
        this.currentFile = f;
        this.$emit('change', this.inputName);
      }
    },
    uploadFile() {
      this.$emit('upload', this.currentFile);
      this.$refs[this.inputName].value = '';
      this.currentFile = null;
    },
  },
};
</script>
