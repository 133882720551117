<template>
  <div :class="{ 'is-loading': isSending }">
    <CCard>
      <CCardHeader>
        <h1>Knowledgebase Data Updater</h1>
      </CCardHeader>
      <CCardBody>
        <CForm>
          <CRow>
            <CCol col="6" class="mb-4">
              <KbFileUpload
                ref="designIntake"
                input-name="designIntake"
                qualifier="DesignIntakeDefaults"
                label="Design Intake KB"
                @upload="uploadDesignIntake"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol col="6" class="mb-4">
              <KbFileUpload
                ref="enumTypes"
                input-name="enumTypes"
                qualifier="VertexEnumValues"
                label="Vertex Enumerated Types KB"
                @upload="uploadVertexEnums"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol col="6" class="mb-4">
              <KbFileUpload
                ref="Enterprise Models KB"
                input-name="entModels"
                qualifier="EntModels"
                label="Enterprise Models KB"
                @upload="uploadEnterpriseModels"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol col="6" class="mb-4">
              <KbFileUpload
                ref="jurisdictions"
                input-name="jurisdictions"
                qualifier="VertexJurisdictions"
                label="Vertex Jurisdictions KB"
                @upload="uploadVertexJurisdictions"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol col="6" class="mb-4">
              <KbFileUpload
                ref="jurisdictionMappings"
                input-name="jurisdictionMappings"
                qualifier="SpecificGeoKeyJurisMap"
                label="Vertex Jurisdiction Mappings KB"
                @upload="uploadJurisdictionMappings"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol col="6" class="mb-4">
              <KbFileUpload
                ref="taxCats"
                input-name="taxCats"
                qualifier="VertexTaxCats"
                label="Vertex Taxability Categories KB"
                @upload="uploadVertexTaxCats"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol col="6" class="mb-4">
              <KbFileUpload
                ref="taxImpositions"
                input-name="taxImpositions"
                qualifier="VertexTaxImpositions"
                label="Vertex Tax Impositions KB"
                @upload="uploadVertexTaxImpositions"
              />
            </CCol>
          </CRow>
        </CForm>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import KbFileUpload from '@/views/admin/components/KbFileUpload';
import API from '@/api/';

export default {
  name: 'AdminKnowledgebase',
  components: {
    KbFileUpload,
  },
  data() {
    return {
      isSending: false,
    };
  },
  computed: {},
  methods: {
    handleImportResult(p) {
      this.isSending = true;
      p.then(() => {
        this.$swal.fire({
          icon: 'success',
          title: 'Complete',
          text: 'Your file was successfully imported.',
        });
      })
        .catch((err) => {
          this.$swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Your file could not be uploaded: ' + err || err.message,
          });
        })
        .finally(() => {
          this.isSending = false;
        });
    },
    uploadDesignIntake(e) {
      this.handleImportResult(API.files.importDesignIntakeKB(e));
    },
    uploadVertexEnums(e) {
      this.handleImportResult(API.files.importEnumTypesKB(e));
    },
    uploadEnterpriseModels(e) {
      this.handleImportResult(API.files.importEntModelsKB(e));
    },
    uploadVertexJurisdictions(e) {
      this.handleImportResult(API.files.importJurisdictionKB(e));
    },
    uploadJurisdictionMappings(e) {
      this.handleImportResult(API.files.importJurisdictionMappingsKB(e));
    },
    uploadVertexTaxCats(e) {
      this.handleImportResult(API.files.importTaxCatsKB(e));
    },
    uploadVertexTaxImpositions(e) {
      this.handleImportResult(API.files.importTaxImpositionsKB(e));
    },
  },
};
</script>
